import img from "../images/ksf-logo2.jpg";
import leaf from "../images/main-slider-icon-2-1.png";
import leaf2 from "../images/leaf-2.png";
import pdf from "../Assets/arbitration pdf.pdf"

const Notice = () => {

  const openPdf = (pdf) => {
    window.open(pdf, "_blank");
  };
  return (
    <div className="bg-[#255946] text-white md:py-5 md:px-10 lg:px-16 md:leading-7 lg:leading-8">
      <div className="flex items-center justify-between">
        <div className="hidden lg:flex lg:flex-col lg:items-start lg:pr-8 lg:mt-36 lg:pl-0 lg:gap-0">
          <img src={leaf} alt="leaf" />
          <img src={leaf} alt="leaf" />
          <img src={leaf} alt="leaf" />
          <img src={leaf} alt="leaf" />
        </div>
        <div className="flex flex-col gap-4 md:w-[95%] lg:w-[75%] justify-center p-6 md:p-0">
          <div className="flex md:flex-col gap-4 flex-row items-center justify-center">
            <img
              src={img}
              alt="kshethrafarms-Logo"
              className="rounded-full w-10 md:w-32 md:mx-auto"
            />
            <p className="text-center text-2xl md:text-3xl font-semibold">
              Important Notice
            </p>
          </div>
          <div className="flex flex-col gap-6 text-sm leading-6 lg:leading-8 md:text-base">
            <div>
              <p>19 July 2024</p>
              <p>To Whom It May Concern,</p>
            </div>
            <div>
              <p className="font-bold">
                Subject: Notice of Application for Arbitration Due to Disputes
                Between Partners
              </p>
            </div>
            <div>
              <p>
                We hereby inform you that our partners have filed an application
                for arbitration to resolve certain disputes that have arisen
                between them. The nature of the disputes is confidential and
                will be addressed through the arbitration process as per the
                agreed terms in our partnership agreement.
              </p>
            </div>
            <div>
              <p>
                We assure you that we are committed to resolving these issues
                amicably and expeditiously to maintain the integrity and smooth
                operation of our business. We will keep you informed of any
                significant developments regarding this matter.
              </p>
            </div>
            <div>
              <p>Thank you for your understanding and cooperation.</p>
            </div>
            <div className="flex flex-col gap-1">
              <p>Sincerely,</p>
              <p>Raghunath Akasam</p>
              <p>Numbercrunchers Careers P Ltd</p>
            </div>
            <div className="text-center cursor-pointer p-2 sm:p-4 md:p-6 lg:p-8 xl:p-10">
              <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl">
                To download Arbitration Order pdf
                <span
                  className="text-red-500 hover:underline ml-1"
                  onClick={() => openPdf(pdf)}
                >
                  click here
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex lg:flex-col lg:items-start lg:mt-36 lg:pl-0 lg:gap-0">
          <img src={leaf2} alt="leaf" />
          <img src={leaf2} alt="leaf" />
          <img src={leaf2} alt="leaf" />
          <img src={leaf2} alt="leaf" />
        </div>
      </div>
    </div>
  );
};

export default Notice;
